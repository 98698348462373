<template>
  <main v-if="isMobile" class="kb-main" id="kb-helpdesk">
    <!-- main-content -->
    <div v-if="isReady" class="main-content main-component">
      <div v-if="items.length === 0"  class="search-result">
        <!-- result-empty -->
        <div class="result-empty">
          <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
          <p class="text">검색결과가 없어요</p>
        </div>
        <!-- //result-empty -->
      </div>
      <div v-else class="notice-list-container">
        <div class="list-top status">
          <div class="top-column">
            <p class="text text-muted">전체 {{ paging.totalCount }}건</p>
          </div>
        </div>
        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" :key="idx" class="board-list-item">
              <router-link :to="{path: '/help/notice/'+item.comNtcSn}" class="board-link"></router-link>
              <div class="list-content">
                <div class="list-content-title">
                  <div v-if="item.topFixYn === 'Y'" class="prefix">
                    <em class="text text-gold">필독</em>
                  </div>
                  <h5 class="title">
                    <span class="text text-truncate">{{item.ntcTitle}}</span>
                    <i v-if="isNew(item)" class="icon-new ms-1"></i>
                  </h5>
                </div>
                <div class="list-meta-content">
                  <span class="text">{{ item.expsrLocNm }}</span>
                  <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}} 작성</span>
                  <span class="text">{{item.inqCnt}}조회</span>
                </div>
              </div>
              <div  v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
            </li>
          </ul>

          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <div v-else class="notice-list-container">
    <div class="list-top">
      <div class="top-column">
        <p class="title text-muted">총 {{paging.totalCount}}건</p>
      </div>
      <div class="top-column">
        <div class="kb-form-search">
          <div class="kb-form-search-field">
            <input v-model.trim="filters.ntcTitle" type="text" class="kb-form-search-input" placeholder="검색어를 입력하세요" @keyup.enter="searchFunc">
            <button class="kb-form-search-btn" @click="searchFunc"><i class="icon-search">검색</i></button>
          </div>
        </div>

<!--        <div class="kb-form-search">-->
<!--          <div class="kb-form-search-field">-->
<!--            <input v-if="filters.ntcTitle" type="text" class="kb-form-search-input" placeholder="검색" @keyup.enter="searchFunc">-->
<!--            <button class="kb-form-search-btn" @keyup.enter="searchFunc"><i class="icon-search">검색</i></button>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <div v-if="isReady" class="board-list-container">
      <div v-if="items.length === 0"  class="search-container">
        <div class="search-result">
          <div class="result-empty">
            <img src="@/assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>
      </div>
      <ul v-else class="board-list">
        <li v-for="(item, index) in items" class="board-list-item" :key="index">
          <article class="board-row">
            <router-link :to="{path: '/help/notice/'+item.comNtcSn}" class="board-link"></router-link>
            <div v-if="item.topFixYn === 'Y'" class="board-column column-index"><em class="text text-gold">필독</em></div>
            <div v-else class="board-column column-index">{{index+1+(paging.pageSize*(paging.pageNo-1))}}</div>
            <div class="board-column column-category"><span class="text text-muted">{{ item.expsrLocNm }}</span></div>
            <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
            <div class="board-column column-title">
              <span class="title">{{item.ntcTitle}}</span>
              <i v-if="isNew(item)" class="icon-new"></i>
            </div>
            <div class="board-column column-date"><span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm')}}</span></div>
            <div class="board-column column-hit"><span class="text">{{item.inqCnt}} 조회</span></div>
          </article>
        </li>
      </ul>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
    </div>
  </div>
</template>

<script>

import CommonPaginationFront from '@/components/CommonPaginationFront';
import {computed, reactive, ref} from 'vue';
import {getValueByQuery} from '@/assets/js/ui.init';
import {useRoute} from 'vue-router';
import {ACT_GET_HELP_NOTICE_LIST} from '@/store/modules/help/help';
// import {expsrLocCds} from '@/assets/js/modules/help/help-common';
import {getItems, getPaging, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {useStore} from 'vuex';
export default {
  name: 'CommonNotice',
  components: {CommonPaginationFront},
  props: {
    expsrLocCdDcd: String
  },
  setup(props) {
    const isMobile = computed(() => store.state.auth.isMobile);
    const store = useStore();
    const route = useRoute();

    const isReady = ref(false);
    const items = ref([]);
    const paging = reactive({
      pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
      pageSize: 10,
      totalCount: 0
    });

    const filters = reactive({
      ntcTitle: ''
    });

    const nowTimestamp = ref(0);
    const newStandardTimestamp = 7 * 24 * 60 * 60 * 1000;

    const getNoticeList = () => {
      isReady.value = true;
      nowTimestamp.value = new Date().getTime();

      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        ntcTitle: filters.ntcTitle,
        expsrLocCd: props.expsrLocCdDcd,
        pageNo: paging.pageNo,
        pageSize: paging.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.totalCount = getPaging(res).totalCount;
          // 새 게시물인지 날짜로 비교
          // items.value.map((x) => {
          //   let diff = new Date(nowDt.value) - new Date(timestampToDateFormat(x.regDt, 'yyyy-MM-dd'));
          //   let month = new Date(diff).getMonth() > 0 ? new Date(diff).getMonth() * 30 : 0;
          //   let date = new Date(diff).getDate();
          //   if(month + date <= 7) x.newNotice = 'Y';
          //   else x.newNotice = 'N';
          // })
        } else {
          items.value = [];
          paging.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    };



    const isNew = item => {
      if (item.regDt > 0) {
        return nowTimestamp.value - item.regDt < newStandardTimestamp
      }
      return false;
    }

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      getNoticeList();
    };

    const searchFunc = () => {
      pagingFunc(1);
    };

    getNoticeList();
    // const getSequenceNum = (index) => {
    //   return (paging.totalCount - ((paging.pageNo - 1) * paging.pageSize) - index);
    // }

    return {
      isMobile,
      isReady,
      items,
      paging,
      filters,

      isNew,
      pagingFunc,
      searchFunc,

      timestampToDateFormat
    }

  }
};
</script>
