import {computed, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";
import {
    ACT_DELETE_LRN_BOARD,
    ACT_DELETE_LRN_BOARD_CMNT, ACT_DELETE_LRN_BOARD_LK,
    ACT_GET_LEARN_BOARD_WITH_COMMENT,
    ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST,
    ACT_INSERT_LRN_BOARD,
    ACT_INSERT_LRN_BOARD_CMNT, ACT_INSERT_LRN_BOARD_LK,
    ACT_UPDATE_LRN_BOARD,
    ACT_UPDATE_LRN_BOARD_CMNT,
    ACT_UPLOAD_LEARN_BOARD_IMAGE
} from "@/store/modules/board/board";
import {clearTargetTimeout, getItem, initParams, isSuccess, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {closeToggles, convertCloudFrontUrl, getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_CRSE_DIST_GRP_SEQ_LIST} from "@/store/modules/course/course";
import {
    confirmDeleteComment,
    confirmInsertComment,
    confirmUpdateComment
} from "@/assets/js/modules/board/comment-common";
import {mentoringDivText} from "@/assets/js/modules/course/mentoring-common";

export const ssmEventSetup = (props, {emit}) => {
    const store = useStore();
    const route = useRoute();
    const isMobile = computed(() => store.state.auth.isMobile);

    const {showConfirm, showMessage} = useAlert();
    const currentTimestamp = new Date().getTime();
    const currentQuery = computed(() => route.query);

    const session = computed(() => store.state.auth.session);
    const fileInput = ref(null);
    const fileInputInEdit = ref(null);
    const editingIndex = ref(-1);

    const contents = ref('');
    const files = ref([]);

    const loadings = reactive({
        seq : false,
        board: false
    });

    const toggles = reactive({
        seq: false,
        file: false,
    });

    const search = reactive({
        search: '',
        distCrseSn: null,
        finpgjum: '',
        finsubbu: '',
    })

    const seqs = ref([]);
    const items = ref([]);

    const paging = reactive({
        pageNo: 1,
        pageSize: 10,
        // pageSize: 1,
        totalCount: 0
    });

    const distCrseGrpSeqSn = ref(0);
    const targetCrseSeq = computed(() => {
        const target = seqs.value.find(x => distCrseGrpSeqSn.value === x.distCrseGrpSeqSn);
        if(target) return target;
        return {distCrseGrpSeqSn: 0}
    });
    const view = computed(() => route.params.view);

    const canWrite = computed(() => targetCrseSeq.value.distCrseGrpSeqSn > 0 && targetCrseSeq.value.bgngDt <= currentTimestamp && targetCrseSeq.value.endDt >= currentTimestamp);

    const addBoard = () => {
        store.dispatch(`board/${ACT_INSERT_LRN_BOARD}`, {
            distCrseSn: targetCrseSeq.value.distCrseSn,
            distCrseGrpSeqSn: distCrseGrpSeqSn.value,
            stt: '00',
            cn: contents.value,
            files: files.value
        }).then(res => {
            if(isSuccess(res)) {
                contents.value = '';
                files.value = [];
                paging.pageNo = 1;
                getBoardWithCommentList();
            } else {
                showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
            }
        }).catch(e => {
            console.error(e);
            showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        });
    }

    const clickBoardAdd = () => {
        if(contents.value) {
            showConfirm({
                title: '이벤트',
                text: '작성된 내용을 등록하시겠습니까?',
                callback: addBoard
            });
        } else {
            showMessage('내용을 입력해주세요.');
        }
    }

    const changeBoard = (index) => {
        store.dispatch(`board/${ACT_UPDATE_LRN_BOARD}`, {
            lrnPostSn: items.value[index].lrnPostSn,
            params: {
                cn: items.value[index].cn,
                files: items.value[index].addedFiles,
                removedFiles: items.value[index].removedFiles,
            }
        }).then(res => {
            if(isSuccess(res)) {
                // 수정된 부분만 갈아껴준다.
                shiftBoardWithComment(index);
            } else {
                showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
            }
        }).catch(e => {
            console.error(e);
            showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        });
    }

    const clickBoardChange = (index) => {
        if(items.value[index].cn) {
            showConfirm({
                title: '이벤트',
                text: '내용을 수정하시겠습니까?',
                callback: () => {changeBoard(index)}
            });
        } else {
            showMessage('내용을 입력해주세요.');
        }
    }

    const removeBoard = (index) => {
        store.dispatch(`board/${ACT_DELETE_LRN_BOARD}`, items.value[index].lrnPostSn).then(res => {
            if(isSuccess(res)){
                showMessage(`삭제되었습니다.`);
                paging.pageNo = 1;
                getBoardWithCommentList();
            }else{
                showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
            }
        }).catch(e => {
            console.error(e);
            showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        });
    }

    const clickBoardRemove = (index) => {
        showConfirm({
            title: '이벤트',
            text: '내용을 삭제하시겠습니까?',
            callback: () => {removeBoard(index)}
        });
    }

    const toggleCommentInput = (index) => {
        items.value[index].addComment = !items.value[index].addComment;
    }

    const clickFileInput = () => {
        fileInput.value.click();
    }

    const getEventFile = (file, res) => {
        const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1);
        return {
            lrnPostFileAtchSn: 0, itnPath: res.path, etnPath: res.path, url: res.url,
            fileNm: file.name,
            fileTyCdDcd: fileExt, fileTyNm: fileExt,
            fileSz: file.size
        }
    }

    const uploadFile = async () => {
        if(files.value.length > 2) {
            showMessage('이미지는 최대 3개까지만 등록할 수 있습니다.');
            fileInput.value.value = '';
            return;
        }

        if(fileInput.value.files && fileInput.value.files.length > 0) {
            const file = fileInput.value.files[0];
            // 이미지 파일만 체크
            if(file.type.includes('image')){
                const uploadRes = await store.dispatch(`board/${ACT_UPLOAD_LEARN_BOARD_IMAGE}`, {contentsType: 'event', files: [file]});
                files.value.push(getEventFile(file, uploadRes));

            }else {
                showMessage('이미지는 파일만 등록해 주세요.');
            }
            fileInput.value.value = '';
        }
    }

    const clickFileInputInEdit = (index) => {
        editingIndex.value = index;
        fileInputInEdit.value.click();
    }

    const uploadFileInEdit = async () => {
        if(items.value[editingIndex.value].addedFiles.length + items.value[editingIndex.value].alreadyFiles.length > 2) {
            showMessage('이미지는 최대 3개까지만 등록할 수 있습니다.');
            fileInputInEdit.value.value = '';
            return;
        }
        if(fileInputInEdit.value.files && fileInputInEdit.value.files.length > 0) {
            const file = fileInputInEdit.value.files[0];
            // 이미지 파일만 체크
            if(file.type.includes('image')){
                const uploadRes = await store.dispatch(`board/${ACT_UPLOAD_LEARN_BOARD_IMAGE}`, {contentsType: 'event', files: [file]});
                items.value[editingIndex.value].addedFiles.push(getEventFile(file, uploadRes));
            }else {
                showMessage('이미지 파일만 등록해 주세요.');
            }
            fileInputInEdit.value.value = '';
        }
    }


    const removeFile = (fileIdx) => {
        showConfirm({
            title: '이미지 삭제',
            text: '선택된 파일을 삭제하시겠습니까?',
            callback: () => {
                files.value.splice(fileIdx, 1);
            }
        });
    }

    // targetKey => alreadyFiles, addedFiles
    const removedFileInUpdate = (index, targetKey, fileIdx) => {
        showConfirm({
            title: '이미지 삭제',
            text: '선택된 파일을 삭제하시겠습니까?',
            callback: () => {
                if(items.value[index][targetKey][fileIdx].lrnPostFileAtchSn > 0) {
                    items.value[index].removedFiles.push(items.value[index][targetKey][fileIdx]);
                }
                items.value[index][targetKey].splice(fileIdx, 1);
            }
        });
    }

    const refineBoard = (item) => {
        item.originCn = item.cn;
        item.comments = item.comments.map(y => ({...y, originCmnt: y.cmnt}));
        item.files = item.files.map(y => ({...y, url: convertCloudFrontUrl(session.value.itn, y, 'itnPath', 'etnPath')}));
        item.alreadyFiles = item.files;
        item.addedFiles = [];
        item.removedFiles = [];
        item.isLike = (item.myLikeCnt > 0);
    }

    const shiftBoardWithComment = (index) => {
        store.dispatch(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT}`, items.value[index].lrnPostSn).then(res => {
            if(lengthCheck(res)) {
                const item = getItem(res);
                item.imgUrl = items.value[index].imgUrl;
                refineBoard(item)
                // edit-mode, toggle 자동 초기화 된다...
                items.value[index] = item;
            }
        });
    }

    const clickSearch = () => {
        pagingFunc(1, paging.pageSize);
    }

    const getBoardWithCommentList = (isInit) => {
        if(distCrseGrpSeqSn.value){
            getListFunc(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST}`, {distCrseGrpSeqSn: distCrseGrpSeqSn.value, stt: '01', ...search, ...paging}, items, paging, () => {
                if(isInit) loadings.board = true;
                items.value.forEach(x => {refineBoard(x);})
            });
        }else{
            items.value = [];
            paging.pageNo = 1;
        }
    }

    const getCourseSequenceList = (isInit) => {
        getListFunc(`course/${ACT_GET_CRSE_DIST_GRP_SEQ_LIST}`, {distCrseSn: props.targetItem.distCrseSn, params: {distCrseGrpBoardTyDcd: props.distCrseGrpBoardTyDcd}}, seqs, null, () => {
            if(isInit) {
                loadings.seq = false;
                loadings.board = false;
                distCrseGrpSeqSn.value = 0;

                // 현재 사용되는 회차를 등록
                if (seqs.value.length > 0) {
                    const target = seqs.value.find(x => x.bgngDt <= currentTimestamp && x.endDt >= currentTimestamp);
                    if (target) {
                        distCrseGrpSeqSn.value = target.distCrseGrpSeqSn;
                    } else {
                        distCrseGrpSeqSn.value = seqs.value[0].distCrseGrpSeqSn;
                    }

                    loadings.seq = true;
                }else{
                    loadings.board = true;
                }
                emit('update:selectedGrpSeqSn', distCrseGrpSeqSn.value);
            }

            getBoardWithCommentList(isInit);
        });
    }

    const changeSeq = () => {
        paging.pageNo = 1;
        emit('update:selectedGrpSeqSn', distCrseGrpSeqSn.value);
        getBoardWithCommentList();
    }

    const pagingFunc = (pageNo, pageSize) => {
        paging.pageNo = pageNo;
        paging.pageSize = pageSize;
        getBoardWithCommentList();
    }

    const toggleDropdown = (index, idx) => {
        if(idx === undefined) {
            items.value.forEach((x, i) => {
                if(i === index) {
                    x.toggle = !x.toggle;
                } else {
                    if(x.toggle) x.toggle = false;
                }
                x.comments.forEach(y => {
                    if(y.toggle) y.toggle = false;
                });
            });
        } else {
            items.value.forEach((x, i) => {
                if(x.toggle) x.toggle = false;
                x.comments.forEach((y, j) => {
                    if(i === index && j === idx) {
                        y.toggle = !y.toggle
                    } else{
                        if(y.toggle) y.toggle = false;
                    }
                });
            });
        }
    }

    const changeMode = (index, idx) => {
        if(idx === undefined) {
            if(!items.value[index].editMode){
                // edit 모드가 풀렸을 때 처리
                items.value[index].cn = items.value[index].originCn;
                items.value[index].alreadyFiles = items.value[index].files;
                items.value[index].removedFiles = [];
                items.value[index].toggle = false;
            }
            items.value[index].editMode = !items.value[index].editMode;
        } else{
            if(!items.value[index].comments[idx].editMode){
                items.value[index].comments[idx].cmnt = items.value[index].comments[idx].originCmnt;
                items.value[index].comments[idx].toggle = false;
            }
            items.value[index].comments[idx].editMode = !items.value[index].comments[idx].editMode;
        }
    }

    const isLoading = ref(false);

    const clickCommentAdd = (index) => {
        const params = {
            lrnPostSn: items.value[index].lrnPostSn,
            params: {cmnt: items.value[index].cmnt}
        };

        confirmInsertComment(`board/${ACT_INSERT_LRN_BOARD_CMNT}`, params, isLoading.value, items.value[index].cmnt, async (res) => {
            if(res.lrnPostCmntSn > 0) {
                const pushItem = {
                    lrnPostCmntSn: res.lrnPostCmntSn,
                    cmnt: items.value[index].cmnt,
                    originCmnt: items.value[index].cmnt,
                    cmntRegDt: new Date().getTime(),
                    cmntLrnerId: session.value.lrnerId,
                    cmntLrnerNm: session.value.lrnerNm,
                    cmntDeptNm: session.value.deptNm,
                    cmntImgUrl: store.state.my.profileImgUrl
                }

                items.value[index].comments.push(pushItem);
                items.value[index].cmnt = '';
            }
        });
    }

    const clickChange = (index, idx) => {
        items.value[index].comments[idx].toggle = false;

        const params = {
            lrnPostCmntSn: items.value[index].comments[idx].lrnPostCmntSn,
            params: {cmnt: items.value[index].comments[idx].cmnt}
        };

        confirmUpdateComment(`board/${ACT_UPDATE_LRN_BOARD_CMNT}`, params, isLoading.value, items.value[index].comments[idx].cmnt, () => {
            items.value[index].comments[idx].originCmnt = items.value[index].comments[idx].cmnt;
            items.value[index].comments[idx].editMode = false;
        });
    }

    const clickRemove = (index, idx) => {
        items.value[index].comments[idx].toggle = false;
        confirmDeleteComment(`board/${ACT_DELETE_LRN_BOARD_CMNT}`, items.value[index].comments[idx].lrnPostCmntSn, isLoading.value, items.value[index].comments);
    }

    const closeAllToggle = () => {
        closeToggles(toggles);

        items.value.forEach(x => {
            if(x.toggle) x.toggle = false;
            x.comments.forEach(y => {
                if(y.toggle) y.toggle = false;
            });
        });
    }

    const toggleBoardLike = (index) => {
        if(!canWrite.value) return;

        clearTargetTimeout(items.value[index].debounce);
        items.value[index].debounce = setTimeout(() => {
            store.dispatch(`board/${items.value[index].isLike ? ACT_INSERT_LRN_BOARD_LK : ACT_DELETE_LRN_BOARD_LK}`, items.value[index].lrnPostSn).then(() => {});
        }, 500);

        if(items.value[index].isLike) {
            items.value[index].likeCnt -= 1;
        } else {
            items.value[index].likeCnt += 1;
        }
        items.value[index].isLike = !items.value[index].isLike;
    }

    const getRefinedText = cn => {
        if (cn) {
            // \n을 <br>로 치환
            cn = cn.replace(/(?:\r\n|\r|\n)/g, '<br>');
            // return cn.replace(/(<([^>]+)>)/gi, "");
        }
        return cn;
    }

    getCourseSequenceList(true);

    watch(() => props.targetItem, () => {
        if(props.targetItem && props.targetItem.distCrseSn > 0) {
            paging.pageNo = 1;
            initParams(search);
            getCourseSequenceList(true);
        }
    });

    watch(() => route.query.search, () => {
        if(route.name.indexOf('SSM') == 0){
            search.search = route.query.search;
            clickSearch();
        }
    })

    return {
        fileInput,
        fileInputInEdit,
        session,
        toggles,
        search,
        loadings,
        seqs,
        distCrseGrpSeqSn,
        view,
        contents,
        files,
        canWrite,
        items,
        paging,
        currentQuery,
        clickFileInput,
        uploadFile,
        clickFileInputInEdit,
        uploadFileInEdit,
        toggleCommentInput,
        pagingFunc,
        clickBoardAdd,
        clickBoardChange,
        clickBoardRemove,

        toggleDropdown,
        changeMode,
        clickCommentAdd,
        clickChange,
        clickRemove,

        changeSeq,
        removeFile,
        removedFileInUpdate,
        getRefinedText,

        timestampToDateFormat,
        closeAllToggle,
        toggleBoardLike,
        isMobile,
        mentoringDivText,
        clickSearch,

    }
}