<template>
  <main class="kb-main" id="kb-sdl-ssm">
    <!-- main-header -->
    <MentoringHeader
        v-if="isReady && !isNone"
        :is-mentor="isMentor"
        :items="items"
        :current-index="currentIndex"
        :title="ssmTitle"
        div="SSM"
        :links="targetLinks"
        :breadcrumbs="['홈', '나의강의실', 'KB 씀(SSM)']"
        :target-item="targetItem"
        v-model:selectedGrpSeqSn="selectedGrpSeqSn"
        @changeCurrentIndex="changeCurrentIndex"
    />
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- sdl-info-container -->
      <template v-if="isReady">
        <div v-if="isNone" class="search-container">
          <div class="result-empty">
            <img src="@/assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">KB씀(SSM) 멘토링 프로그램 대상자가 아닙니다. </p>
          </div>
        </div>
        <template v-else-if="view === 'main'">
          <MentoringMeta
              :mentor="mentor"
              :mentee="mentee"
              :target-item="targetItem"
          />
          <SSMEventStatus
              v-if="isSSM && targetItem.distCrseSn > 0"
              :target-item="targetItem"
              dist-crse-grp-board-ty-dcd="2162001"
          />
        </template>
        <CommonNotice
            v-else-if="view === 'notice'"
            :expsr-loc-cd-dcd="expsrLocCds.EXPSR_LOC_CD_SSM"
        />
        <MentoringBoard
            v-else-if="view === 'diary'"
            :target-item="targetItem"
            title="우리의 이야기"
            write="글쓰기"
        />
        <SSMEventBoard
            v-else-if="view === 'board'"
            :target-item="targetItem"
            title="학습노트"
            dist-crse-grp-board-ty-dcd="2162001"
            v-model:selectedGrpSeqSn="selectedGrpSeqSn"
        />
        <SSMEvent
            v-else-if="view === 'event'"
            :target-item="targetItem"
            dist-crse-grp-board-ty-dcd="2162002"
            v-model:selectedGrpSeqSn="selectedGrpSeqSn"
        />

        <BoardReadOnly
            v-else-if="view === 'commonBoard'"
            :board-id="`rm-mate-board`"
        />

      </template>
    </div>
    <!-- //main-content -->
  </main>
</template>
<script>

import MentoringHeader from '@/components/prep/mentoring/MentoringHeader';
import {useStore} from 'vuex';
import {ACT_GET_CRSE_DIST_MENTORING} from '@/store/modules/course/course';
import {getItem, lengthCheck} from '@/assets/js/util';
import {computed, ref, watch} from 'vue';
import {grpRoleCdDcds} from '@/assets/js/modules/course/mentoring-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import MentoringMeta from '@/components/prep/mentoring/MentoringMeta';
import {useRoute, useRouter} from 'vue-router';
import MentoringBoard from '@/components/prep/mentoring/MentoringBoard';
import SSMEvent from '@/components/prep/ssm/SSMEvent';
import SSMEventBoard from '@/components/prep/ssm/SSMEventBoard.vue';
import SSMEventStatus from '@/components/prep/ssm/SSMEventStatus.vue';
import navigationUtils from "@/assets/js/navigationUtils";
import {expsrLocCds} from '@/assets/js/modules/help/help-common';
import CommonNotice from '@/components/common/CommonNotice';
import BoardReadOnly from '@/components/board/BoardReadOnly'

export default {
  name: 'SSMMain',
  components: {CommonNotice, SSMEventStatus, SSMEvent, SSMEventBoard, MentoringBoard, MentoringMeta, MentoringHeader,BoardReadOnly},
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showLoading, hideLoading} = useAlert();
    const view = computed(() => route.params.view || 'main');
    const targetLinks =ref([{key: 'main', name: 'SSM 정보'},{key: 'notice', name: '공지사항'},{key: 'diary', name: '우리의 이야기'},{key: 'board', name: '학습노트'},{key: 'event', name: '이벤트'}]);

    const isMentor = ref(false);
    const isReady = ref(false);
    const isNone = ref(false);
    const isSSM = computed(() => route.name.indexOf('SSM') === 0);

    const currentIndex = ref(-1);
    const ssmTitle = ref('');
    const items = ref([]);
    const selectedGrpSeqSn = ref(0);

    const targetItem = computed(() => {
      if(currentIndex.value > -1 && items.value[currentIndex.value]){
        return items.value[currentIndex.value];
      }
      return {
        distCrseSn: 0,
        classCateSn: 0,
        learners: []
      }
    });

    const mentor = computed(() => {
      if(targetItem.value.distCrseSn > 0){
        return targetItem.value.learners.find(x => x.grpRoleCdDcd === grpRoleCdDcds.leader) || {lrnTrgtGrpDtlSn: 0};
      }
      return {lrnTrgtGrpDtlSn: 0};
    })

    const mentee = computed(() => {
      if(targetItem.value.distCrseSn > 0){
        return targetItem.value.learners.find(x => x.grpRoleCdDcd === grpRoleCdDcds.learner) || {lrnTrgtGrpDtlSn: 0};
      }
      return {lrnTrgtGrpDtlSn: 0};
    });

    const changeCurrentIndex = (index) => {
      router.push({query: {distCrseSn : items.value[index].distCrseSn}});
    }

    const getMentoring = () => {
      store.dispatch(`course/${ACT_GET_CRSE_DIST_MENTORING}`, {splCrseTyCdDcd: '2066016'}).then(res => {
        if(lengthCheck(res)) {
          const item = getItem(res);
          if(item.mentorings.length > 0) {
            isMentor.value = item.mentor;
            items.value = item.mentorings;
            currentIndex.value = 0;
            // 새로고침 하거나 글 등록 후 리 다이렉트 시 멘티 세팅 (currentIndex 세팅)
            const distCrseSn = route.query.distCrseSn ? parseInt(route.query.distCrseSn) : 0;
            if(undefined!==route.query.distCrseSn) currentIndex.value = items.value.map(x => x.distCrseSn).indexOf(distCrseSn);
          }else{
            isNone.value = true;
          }
        }
        isReady.value = true;
        hideLoading();
      }).catch(e => {
        console.error(e);
        hideLoading();
      });
    }

    // const initLisks = ()=>{
    //   if(targetLinks.value.filter(x => x.key === 'rm-mate-board').length === 0 && currentIndex.value.distCrseSn ===' '
    // }

    watch(() => route.query.distCrseSn, () => {
      if(isSSM.value) {
        const distCrseSn = route.query.distCrseSn ? parseInt(route.query.distCrseSn) : 0;
        currentIndex.value = items.value.map(x => x.distCrseSn).indexOf(distCrseSn);


      }

    });

    watch(()=> currentIndex.value ,()=>{
      try{
        ssmTitle.value = 'KB 씀(SSM) - ' + items.value[currentIndex.value].distCrseGrpNm;
      }catch (e) {
        ssmTitle.value = 'KB 씀(SSM)';
      }
    });

    showLoading();
    getMentoring();

    return {
      view,
      isReady,
      isNone,
      isMentor,
      isSSM,
      items,
      currentIndex,
      selectedGrpSeqSn,
      targetItem,
      ssmTitle,
      mentor,
      mentee,
      changeCurrentIndex,
      expsrLocCds,
      isMobile: navigationUtils.any(),
      targetLinks,
    }
  }
};
</script>
