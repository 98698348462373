<template>
  <div class="ssm-container" @click="closeAllToggle">
    <div v-if="loadings.seq" class="list-top">
      <div class="top-column">
        <!-- list-top > list-sort -->
        <div class="list-sort">
          <div class="sort">
            <!-- kb-form-dropdown -->
            <SortButtonSelect
                v-model="distCrseGrpSeqSn"
                v-model:toggle="toggles.seq"
                title="이벤트"
                :options="seqs"
                sequence-key="distCrseGrpSeqSn"
                name-key="distCrseGrpSeqNm"
                :option-style="isMobile ? {left: '-10px'} : {}"
                @selected="changeSeq"
            />
            <!-- //kb-form-dropdown -->
          </div>
        </div>
      </div>
    </div>

    <div class="ssm-my-comment comment-container">
      <div class="comment-wrapper">
        <article class="comment">

          <template v-if="!isMobile">
            <div class="comment-avatar">
              <div class="avatar">
                <ProfileImg :target="session"/>
              </div>
            </div>
            <div class="comment-contents">
              <div class="content-metadata">
                <div class="metadata is-me">{{ session.lrnerNm }} ・ {{ session.deptNm }}</div>
                <div class="metadata metadata-date"></div>
              </div>
              <div class="content-form">
                <textarea v-model.trim="contents" class="kb-form-control p-3 bg-body" :placeholder="canWrite ? '내용을 입력하세요' : '이벤트 기간이 아닙니다.'" :disabled="!canWrite"></textarea>
              </div>
              <input ref="fileInput" type="file" accept="image/*" class="d-none" @change="uploadFile" />

              <div class="content-files ">
                <div v-for="(file, index) in files" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="index" @click="removeFile(index)">
                  <div class="file-link">
                    <img :src="file.url" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="canWrite" class="comment-actions">
              <div class="dropdown" :class="{'is-active': toggles.file}">
                <button class="dropdown-btn kb-btn-actions" @click.stop="toggles.file = !toggles.file"><i class="icon-menu"></i></button>
                <div class="dropdown-target">
                  <div class="dropdown-box">
                    <ul class="dropdown-option-list">
                      <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickFileInput"><span class="dropdown-option-text">사진앨범</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <button class="kb-btn kb-btn-primary" @click="clickBoardAdd"><span class="text">등록</span></button>
            </div>
          </template>

          <template v-else>
            <div class="comment-row">
              <div class="comment-avatar">
                <div class="avatar">
                  <ProfileImg :target="session"/>
                </div>
              </div>
              <div class="comment-contents" >
                <div class="content-metadata">
                  <div class="metadata is-me">{{ session.lrnerNm }} ・ {{ session.deptNm }}</div>
                  <div class="metadata metadata-date"></div>
                </div>
              </div>
              <div v-if="canWrite" class="comment-actions">
                <div class="dropdown" :class="{'is-active': toggles.file}">
                  <button class="dropdown-btn kb-btn-actions" @click.stop="toggles.file = !toggles.file"><i class="icon-menu"></i></button>
                  <div class="dropdown-target">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickFileInput"><span class="dropdown-option-text">사진앨범</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="comment-form">
              <textarea v-model.trim="contents" class="kb-form-control" :placeholder="canWrite ? '내용을 입력하세요' : '이벤트 기간이 아닙니다.'" :disabled="!canWrite"></textarea>
              <input ref="fileInput" type="file" accept="image/*" class="d-none" @change="uploadFile" />

              <div class="content-files">
                <div v-for="(file, index) in files" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="index" @click="removeFile(index)">
                  <div class="file-link">
                    <img :src="file.url" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="canWrite" class="comment-bottom">
              <button class="kb-btn kb-btn-primary" @click="clickBoardAdd"><span class="text">등록</span></button>
            </div>
          </template>

        </article>
      </div>
    </div>

    <div v-if="loadings.board" class="ssm-comment comment-container">
      <input ref="fileInputInEdit" type="file" accept="image/*" class="d-none" @change="uploadFileInEdit" />
      <div class="comment-wrapper">
        <div v-if="!isMobile" class="comment-list-top"></div>
        <ul class="comment-list">
          <!-- comment-item -->
          <li v-for="(item, index) in items" class="comment-item" :key="index">
            <!-- comment -->
            <article class="comment">
              <div class="comment-avatar">
                <div class="avatar">
                  <CommonTypeImage
                      :img-src="item.imgUrl"
                      theme="profile"
                  />
                </div>
              </div>

              <template v-if="!isMobile">
                <div class="comment-contents">
                  <div class="content-metadata">
                    <div class="metadata">{{ item.lrnerNm }} ・ {{ item.deptNm }}</div>
                    <div class="metadata metadata-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                  </div>

                  <template v-if="item.editMode">
                    <div class="content-form">
                      <textarea v-model.trim="item.cn" class="kb-form-edit-control p-3 bg-body" placeholder="내용을 입력하세요"></textarea>
                    </div>
                    <div class="content-files">
                      <div v-for="(file, idx) in item.alreadyFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'alreadyFiles', idx)">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                      <div v-for="(file, idx) in item.addedFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'addedFiles', idx)">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                    </div>
                    <div class="board-edit-actions">
                      <button class="kb-btn kb-btn-secondary" @click="changeMode(index)"><span class="text">취소</span></button>
                      <button class="kb-btn kb-btn-primary" @click="clickBoardChange(index)"><span class="text">수정</span></button>
                      <button class="kb-btn kb-btn-dark" @click="clickFileInputInEdit(index)"><span class="text">사진</span></button>
                   </div>
                  </template>
                  <template v-else>
                    <div class="content-text">
                      <p class="text" v-html="getRefinedText(item.originCn)"></p>
                    </div>
                    <div class="content-files">
                      <div v-for="(file, idx) in item.files" class="file" style="height: 100%; margin-right: 16px;" :key="idx">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                    </div>
                    <div class="content-actions">
                      <button class="kb-btn-like d-flex align-items-center" @click="toggleBoardLike(index)"><i class="icon-like2" :class="{'is-active': item.isLike}"></i><span class="text">{{ item.likeCnt > 0 ? item.likeCnt : 0 }}</span></button>
                      <button class="kb-btn-comment d-flex align-items-center ms-2" @click="toggleCommentInput(index)"><i class="icon-comment2"></i><span class="text">{{ item.comments.length }}</span></button>
                    </div>
                  </template>

                </div>
              </template>

              <template v-else>
                <div class="comment-contents mt-3 mb-3">
                  <template v-if="item.editMode">
                    <div class="content-form">
                      <textarea v-model.trim="item.cn" class="kb-form-edit-control" placeholder="내용을 입력하세요"></textarea>
                    </div>
                    <div class="content-files">
                      <div v-for="(file, idx) in item.alreadyFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'alreadyFiles', idx)">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                      <div v-for="(file, idx) in item.addedFiles" class="file event-files-edit kb-mouse-cursor" style="margin-right: 16px;" :key="idx" @click="removedFileInUpdate(index, 'addedFiles', idx)">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                    </div>
                    <div class="board-edit-actions">
                      <button class="btn" @click="changeMode(index)"><span class="text">취소</span></button>
                      <button class="kb-btn kb-btn-primary" @click="clickBoardChange(index)"><span class="text">수정</span></button>
                      <button class="kb-btn kb-btn-dark" @click="clickFileInputInEdit(index)"><span class="text">사진</span></button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="content-text">
                      <p class="text" v-html="getRefinedText(item.originCn)"></p>
                    </div>
                    <div class="content-files">
                      <div v-for="(file, idx) in item.files" class="file" style="margin-right: 16px;" :key="idx">
                        <div class="file-link"><img :src="file.url" alt=""></div>
                      </div>
                    </div>
                  </template>
                  <div class="content-row row-bottom flex-column">
                    <div class="content-actions">
                      <button class="kb-btn-like" @click="toggleBoardLike(index)"><i class="icon-like" :class="{'is-active': item.isLike}"></i><span class="text">{{ item.likeCnt > 0 ? item.likeCnt : 0 }}</span></button>
                      <button class="kb-btn-comment ms-2" @click="toggleCommentInput(index)"><i class="icon-comment"></i><span class="text">{{ item.comments.length }}</span></button>
                    </div>
                    <div class="content-metadata">
                      <div class="metadata">{{ item.lrnerNm }} ・ {{ item.deptNm }}</div>
                      <div class="metadata metadata-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <div v-if="session.lrnerId === item.lrnerId && !item.editMode && canWrite" class="comment-actions">
                <div class="dropdown" :class="{'is-active': item.toggle}">
                  <button class="dropdown-btn kb-btn-actions" @click.stop="toggleDropdown(index)"><i class="icon-menu"></i></button>
                  <div class="dropdown-target" style="top: 24px;">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index)"><span class="dropdown-option-text">수정</span></a></li>
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickBoardRemove(index)"><span class="dropdown-option-text">삭제</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <!-- //comment -->
            <!-- ssm-comment -->

            <div class="ssm-comment comment-container" :class="{'is-active': item.addComment}">
              <div v-if="item.addComment"  class="comment-write">
                <div class="kb-form-comment">
                  <input v-model.trim="item.cmnt" type="text" class="kb-form-comment-input" placeholder="댓글 달기" :disabled="!canWrite" @keyup.enter="clickCommentAdd(index)">
                  <button class="kb-btn kb-btn-secondary kb-btn-comment" :disabled="!canWrite" @click="clickCommentAdd(index)">
                    <span class="text">댓글</span>
                  </button>
                </div>
              </div>
              <div v-if="item.comments.length > 0" class="comment-wrapper">
                <ul class="comment-list">
                  <!-- comment-item -->
                  <li v-for="(comment, idx) in item.comments" class="comment-item" :key="`${index}-${idx}`">
                    <article class="comment">
                      <div class="comment-avatar">
                        <div class="avatar">
                          <CommonTypeImage
                              :img-src="comment.cmntImgUrl"
                              theme="profile"
                          />
                        </div>
                      </div>
                      <div class="comment-contents">
                        <div class="content-text" :class="{'comment-edit-mode': comment.editMode}">
                          <template v-if="comment.editMode">
                            <div class="comment-form">
                              <div>
                                <input v-model.trim="comment.cmnt" type="text" class="comment-field" @keyup.enter="clickChange(index, idx)"/>
                              </div>
                            </div>
                          </template>
                          <p v-else class="text">{{ comment.originCmnt }}</p>
                        </div>
                        <div class="content-metadata">
                          <div class="metadata">{{ comment.cmntLrnerNm }} ・ {{ comment.cmntDeptNm }}</div>
                          <div class="metadata metadata-date">{{ timestampToDateFormat(comment.cmntRegDt, 'yyyy.MM.dd hh:mm') }}</div>
                        </div>
                        <div v-if="comment.editMode" class="comment-actions">
                          <button class="btn" @click="changeMode(index, idx)"><span class="text">취소</span></button>
                          <button class="btn" @click="clickChange(index, idx)"><span class="text">수정</span></button>
                        </div>
                      </div>
                      <div v-if="session.lrnerId === comment.cmntLrnerId && !comment.editMode && canWrite" class="comment-actions">
                        <div class="dropdown" :class="{'is-active': comment.toggle}">
                          <button class="dropdown-btn kb-btn-actions" @click.stop="toggleDropdown(index, idx)"><i class="icon-menu"></i></button>
                          <div class="dropdown-target">
                            <div class="dropdown-box">
                              <ul class="dropdown-option-list">
                                <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index, idx)"><span class="dropdown-option-text">수정</span></a></li>
                                <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickRemove(index, idx)"><span class="dropdown-option-text">삭제</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </li>
                </ul>
              </div>
            </div>
            <!-- //ssm-comment -->
          </li>
        </ul>
        <div class="comment-list-bottom">
          <CommonPaginationFront
              :paging="paging"
              :page-func="pagingFunc"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProfileImg from '@/components/common/ProfileImg';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import CommonTypeImage from '@/components/common/CommonTypeImage';
import {ssmEventSetup} from "@/assets/js/modules/course/ssm-event-setup";

export default {
  name: 'SSMEvent',
  components: {
    CommonTypeImage,
    SortButtonSelect,
    CommonPaginationFront,
    ProfileImg,
  },
  props: {
    targetItem: Object,
    distCrseGrpBoardTyDcd: String,
    selectedGrpSeqSn: Number,
  },
  emits: ['update:selectedGrpSeqSn'],
  setup: ssmEventSetup
};
</script>
