<template>
  <template v-if="isMobile">
    <div class="sdl-info-container">
      <div class="info-card info-status">
        <div class="status align-items-center">
          <strong class="title">멘토</strong>
          <div v-if="data.mentor > 2" class="badge badge-primary me-2"><span class="badge-text">수료</span></div>
          <span class="text">{{ data.mentor }}회</span>
        </div>
        <div class="status align-items-center">
          <strong class="title">멘티</strong>
          <div v-if="data.mentee > 2" class="badge badge-primary me-2"><span class="badge-text">수료</span></div>
          <span class="text">{{ data.mentee }}회</span>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="sdl-card-cont">
      <div class="card-cont">
        <div class="card-status">
          <strong class="title">멘토</strong>
          <div class="d-flex">
            <span class="text">{{ data.mentor }}회</span>
            <div v-if="data.mentor > 2" class="badge badge-primary ms-2"><span class="badge-text">수료</span></div>
          </div>
        </div>
        <div class="card-status">
          <strong class="title">멘티</strong>
          <div class="d-flex">
            <span class="text">{{ data.mentee }}회</span>
            <div v-if="data.mentee > 2" class="badge badge-primary ms-2"><span class="badge-text">수료</span></div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {onMounted, reactive, watch} from "vue";
import {useStore} from "vuex";
import {ACT_GET_LRN_BOARD_EVENT_STATUS} from "@/store/modules/board/board";
import {getItem, initParams, lengthCheck, setParams} from "@/assets/js/util";
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'SSMEventStatus',
  props: {
    targetItem: Object,
    distCrseGrpBoardTyDcd: String,
  },
  setup(props) {
    const store = useStore();
    const data = reactive({
      mentor: 0,
      mentee: 0,
    });

    const getLrnBoardEventStatus = () => {
      if(props.targetItem.distCrseSn > 0){
        store.dispatch(`board/${ACT_GET_LRN_BOARD_EVENT_STATUS}`, {distCrseSn: props.targetItem.distCrseSn, distCrseGrpBoardTyDcd: props.distCrseGrpBoardTyDcd}).then(res => {
          if(lengthCheck(res)){
            setParams(data, getItem(res));
          }else{
            initParams(data);
          }
        })
      }
    }

    watch(() => [props.targetItem], getLrnBoardEventStatus)

    onMounted(getLrnBoardEventStatus)

    return {
      data,
      isMobile: navigationUtils.any(),
    }
  }
}
</script>
<script setup>
</script>