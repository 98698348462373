<template>
  <template v-if="isMobile">
    <div v-if="loadings.seq" class="notice-list-container">
      <div class="list-top status">
        <div class="top-column">
          <p class="text text-muted">총 {{paging.totalCount}}건</p>
          <div v-if="seqs.length > 1" class="list-sort ms-3">
            <div class="sort">
              <SortButtonSelect
                  v-model="distCrseGrpSeqSn"
                  v-model:toggle="toggles.seq"
                  :options="seqs"
                  sequence-key="distCrseGrpSeqSn"
                  name-key="distCrseGrpSeqNm"
                  :option-style="isMobile ? {left: '-10px'} : {}"
                  @selected="changeSeq"
              />
            </div>
          </div>
        </div>
        <div class="top-column">
          <div class="kb-form-check">
            <input v-model="search.distCrseSn" class="kb-form-check-input" type="checkbox" id="checkbox-ssm-my" :true-value="targetItem.distCrseSn" :false-value="null" @change="clickSearch" />
            <label class="kb-form-check-label" for="checkbox-ssm-my"><span class="text">My</span></label>
          </div>
          <div class="kb-form-check ms-2">
            <input v-model="search.finpgjum" class="kb-form-check-input" type="checkbox" id="checkbox-ssm-pg" :true-value="session.lrnerId" :false-value="null" @change="clickSearch" />
            <label class="kb-form-check-label" for="checkbox-ssm-pg"><span class="text">우리 PG</span></label>
          </div>
          <div class="kb-form-check ms-2">
            <input v-model="search.finsubbu" class="kb-form-check-input" type="checkbox" id="checkbox-ssm-grp" :true-value="session.lrnerId" :false-value="null" @change="clickSearch" />
            <label class="kb-form-check-label" for="checkbox-ssm-grp"><span class="text">우리 그룹</span></label>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="ssm-container board-list-container" @click="closeAllToggle">
      <div v-if="loadings.seq" class="list-top">
        <div class="top-column">
          <div v-if="seqs.length > 1" class="list-sort me-3">
            <div class="sort">
              <!-- kb-form-dropdown -->
              <SortButtonSelect
                  v-model="distCrseGrpSeqSn"
                  v-model:toggle="toggles.seq"
                  :options="seqs"
                  sequence-key="distCrseGrpSeqSn"
                  name-key="distCrseGrpSeqNm"
                  :option-style="isMobile ? {left: '-10px'} : {}"
                  @selected="changeSeq"
              />
              <!-- //kb-form-dropdown -->
            </div>
          </div>
          <p class="title text-muted">총 {{paging.totalCount}}건</p>
          <div class="kb-form-check ms-3">
            <input v-model="search.distCrseSn" class="kb-form-check-input" type="checkbox" id="checkbox-ssm-my" :true-value="targetItem.distCrseSn" :false-value="null" @change="clickSearch" />
            <label class="kb-form-check-label" for="checkbox-ssm-my">My</label>
          </div>
          <div class="kb-form-check ms-3">
            <input v-model="search.finpgjum" class="kb-form-check-input" type="checkbox" id="checkbox-ssm-pg" :true-value="session.lrnerId" :false-value="null" @change="clickSearch" />
            <label class="kb-form-check-label" for="checkbox-ssm-pg">우리 PG</label>
          </div>
          <div class="kb-form-check ms-3">
            <input v-model="search.finsubbu" class="kb-form-check-input" type="checkbox" id="checkbox-ssm-grp" :true-value="session.lrnerId" :false-value="null" @change="clickSearch" />
            <label class="kb-form-check-label" for="checkbox-ssm-grp">우리 그룹</label>
          </div>
        </div>
        <div class="top-column">
          <div class="kb-form-search">
            <div class="kb-form-search-field">
              <input type="text" class="kb-form-search-input" placeholder="검색어를 입력하세요"
                     v-model="search.search"
                     @keyup.enter="clickSearch"
              >
              <button class="kb-form-search-btn" @click="clickSearch"><i class="icon-search">검색</i></button>
            </div>
          </div>
          <button v-if="canWrite" class="kb-btn kb-btn-primary" type="button" @click="$router.push({name:'SSMWrite', params: {distCrseSn: targetItem.distCrseSn, view: view, targetSn: 0, distCrseGrpSeqSn: distCrseGrpSeqSn}, query: currentQuery})">
            <span class="text">글쓰기</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  <template v-if="loadings.board">
    <div v-if="items.length > 0" class="board-list-container">
      <ul class="board-list" :class="{'divider-list': !isMobile}">
        <li v-for="(item, index) in items" :class="isMobile ? 'board-list-item' : 'board-item divider-item'" :key="index">
          <div :class="isMobile ? 'w-100' : 'item-card'">
            <div :class="isMobile ? 'list-content w-100' : 'card-content'">
              <div :class="isMobile ? 'list-content-title' : 'content-title'">
                <h4 class="title">
                  <div v-if="item.stt == '01'" class="d-flex align-items-center">
                    <router-link :to="{name: `SSMWrite`, params: {distCrseSn: targetItem.distCrseSn, view: view, targetSn: item.lrnPostSn, distCrseGrpSeqSn: distCrseGrpSeqSn}, query: currentQuery}" class="title-link overflow-x-hidden text-truncate">{{ item.title }}</router-link>
                    <div class="badge badge-silver ms-2"><span class="badge-text">작성중</span></div>
                  </div>
                  <router-link v-else :to="{name: `SSMView`, params: {view: view, targetSn: item.lrnPostSn}, query: currentQuery}" class="title-link overflow-x-hidden text-truncate">{{ item.title }}</router-link>
                </h4>
              </div>
              <div v-if="isMobile" class="list-meta-content">
                <span class="text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span>
                <span class="text">{{ item.lrnerNm }} ・ {{ item.deptNm }}</span>
              </div>
              <div v-else class="content-meta">
                <div class="meta"><span class="meta-text">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</span></div>
                <div class="meta"><span class="meta-text text-muted">{{ item.lrnerNm }} ・ {{ item.deptNm }}</span></div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
    </div>
    <div v-else class="search-container">
      <div class="result-empty">
        <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
        <p class="text">{{ title }} 정보가 없습니다.</p>
      </div>
    </div>
  </template>
</template>

<script>

import CommonPaginationFront from '@/components/CommonPaginationFront';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import {ssmEventSetup} from "@/assets/js/modules/course/ssm-event-setup";

export default {
  name: 'SSMEventBoard',
  components: {
    SortButtonSelect,
    CommonPaginationFront,
  },
  props: {
    targetItem: Object,
    distCrseGrpBoardTyDcd: String,
    title: String,
    selectedGrpSeqSn: Number,
  },
  emits: ['update:selectedGrpSeqSn'],
  setup: ssmEventSetup
};
</script>
